import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosInstance from '../axiosInstance';
import ReactMarkdown from 'react-markdown';
import BotImage from '../assets/Grant_photo.webp';
import LoadingIcon from '../assets/logogrants.png';
import config from '../config';
import '../assets/css/ImprovedGrantsChatbot.css';

const ChatMessage = ({ message, isUser }) => (
  <div className={`message-container ${isUser ? 'user' : 'bot'}`}>
    {!isUser && <img src={BotImage} alt="Bot" className="bot-image" />}
    <div className={`message-bubble ${isUser ? 'user-bubble' : 'bot-bubble'}`}>
      <ReactMarkdown>{message.content}</ReactMarkdown>
    </div>
  </div>
);

const ImprovedGrantsChatbot = ({ grantId = null }) => {
  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    setMessages([{ role: 'assistant', content: 'Hello, how can I help you today?' }]);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, loading]);

  const typeMessage = (text) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setMessages(prev => {
          const updated = [...prev];
          updated[updated.length - 1].content += text.charAt(index);
          return updated;
        });
        index++;
      } else {
        clearInterval(interval);
      }
    }, 5); // velocidade de digitação (ajuste como preferir)
  };

  const handleSend = async () => {
    if (!query.trim()) return;

    setLoading(true);
    setError(null);
    setMessages(prev => [...prev, { role: 'user', content: query }]);
    setQuery('');

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      const response = await axiosInstance.post(
        `${config.ApiHost_User}/api/grant_details`,
        { grant_id: grantId, question: query },
        { cancelToken: source.token }
      );

      const botResponse = response.data.assistant_response;
      setMessages(prev => [...prev, { role: 'assistant', content: '' }]); // inicia vazio para digitação
      typeMessage(botResponse);
    } catch (err) {
      if (!axios.isCancel(err)) setError('Error getting a response from the server.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (cancelTokenSource) cancelTokenSource.cancel('Request canceled by user.');
    setLoading(false);
  };

  return (
    <div className="chatbot-container">
      <h1 className="chatbot-header">
        {grantId ? 'AI Assistant for this Grant' : 'AI Assistant'}
      </h1>

      <div className="chatbox" ref={chatBoxRef}>
        {messages.map((msg, i) => (
          <ChatMessage key={i} message={msg} isUser={msg.role === 'user'} />
        ))}

        {loading && (
          <div className="message-container bot">
            <img src={BotImage} alt="Bot" className="bot-image" />
            <div className="message-bubble bot-bubble">
              <img src={LoadingIcon} alt="Carregando" className="loading-icon" />
            </div>
          </div>
        )}

        {error && <p className="chat-error">{error}</p>}
      </div>

      <div className="chat-input-container">
        <input
          type="text"
          className="chat-input"
          placeholder="Type your message"
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && !loading && handleSend()}
          disabled={loading}
        />
        <button className="chat-send-button" onClick={handleSend} disabled={loading}>
          Send
        </button>
        {loading && (
          <button className="chat-cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default ImprovedGrantsChatbot;
