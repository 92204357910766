import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import BotImage from '../assets/Grant_photo.webp'; // Ajuste para o caminho correto do seu asset
import LoadingIcon from '../assets/logogrants.png'; // Ajuste para o caminho correto do seu asset
import config from '../config'; // Arquivo de configuração onde está, por exemplo, config.apiHost

// Estilos básicos (você pode mover para um arquivo .css separado se preferir)
const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    marginTop: '100px',
    marginBottom: '100px',
    background: 'linear-gradient(145deg, #ffffff, #dfe9f3)',
    borderRadius: '20px',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
    fontFamily: "'Roboto', sans-serif",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh'
  },
  header: {
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
    padding: '0 10px',
    fontFamily: "'Helvetica Neue', Arial, sans-serif"
  },
  highlightedText1: {
    color: '#FF6F61'
  },
  highlightedText2: {
    color: '#00BFFF'
  },
  chatBox: {
    flex: 1,
    border: '1px solid #DDDDDD',
    padding: '10px',
    borderRadius: '10px',
    overflowY: 'auto',
    backgroundColor: '#F9F9F9'
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '10px'
  },
  messageUser: {
    justifyContent: 'flex-end'
  },
  messageBot: {
    justifyContent: 'flex-start'
  },
  botImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  messageBubble: {
    display: 'inline-block',
    padding: '10px 15px',
    borderRadius: '25px',
    maxWidth: '80%',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    textAlign: 'left',
    lineHeight: '1.4',
    whiteSpace: 'pre-wrap'
  },
  loadingMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingIcon: {
    width: '24px',
    height: '24px',
    margin: '0 10px'
  },
  error: {
    color: 'red',
    marginTop: '10px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px'
  },
  input: {
    flex: 1,
    padding: '15px',
    marginRight: '10px',
    borderRadius: '25px',
    border: '1px solid #ddd',
    fontSize: '14px',
    outline: 'none'
  },
  button: {
    padding: '12px 25px',
    borderRadius: '25px',
    border: 'none',
    backgroundColor: '#1BBCEA',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '10px',
    transition: 'background-color 0.3s ease'
  },
  cancelButton: {
    padding: '12px 25px',
    borderRadius: '25px',
    border: 'none',
    backgroundColor: '#FF3B30',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '10px',
    transition: 'background-color 0.3s ease'
  },
  suggestionsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    marginTop: '10px',
    gap: '6px'
  },
  suggestionBubble: {
    padding: '8px 15px',
    borderRadius: '25px',
    backgroundColor: '#fd9a6f',
    color: 'white',
    cursor: 'pointer',
    fontSize: '12px',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    transition: 'opacity 0.2s ease'
  }
};

// Para animação de loading
const globalStyles = `
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loading {
      animation: spin 1s linear infinite;
  }
`;

/**
 * Componente que representa uma única mensagem do Chat
 */
const ChatMessage = ({ message, isUser }) => {
  return (
    <div 
      style={{
        ...styles.messageContainer,
        ...(isUser ? styles.messageUser : styles.messageBot)
      }}
    >
      {!isUser && (
        <img
          src={BotImage}
          alt="Bot"
          style={styles.botImage}
        />
      )}

      <div
        style={{
          ...styles.messageBubble,
          backgroundColor: isUser ? '#0078FF' : '#FFF',
          color: isUser ? 'white' : 'black'
        }}
      >
        <ReactMarkdown>
          {message.content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

/**
 * Componente principal do chatbot
 */
const ImprovedGrantsChatbot = ({
  grantId,
  all,
  gpt_response,
  initialMessage
}) => {

  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [typingInterval, setTypingInterval] = useState(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const chatBoxRef = useRef(null);
  
  /**
   * Sugestões de prompts caso NÃO tenha um grantId
   */
  const defaultSuggestions = [
    'List grants open this month?',
    'What is a grant?',
    'List grants open today?',
    'Show me available grants?',
    'What grant opportunities are available for agency code DOE-01?'
  ];

  /**
   * Sugestões específicas se TIVER um grantId
   */
  const grantSpecificSuggestions = [
    'What are the eligibility criteria for this grant?',
    'Can you provide details on the application process?',
    'What is the funding range for this grant?',
    'What are the important deadlines?',
    'Are there any specific requirements for this grant?'
  ];

  // Escolhe qual array de sugestões será exibido
  const suggestions = grantId ? grantSpecificSuggestions : defaultSuggestions;

  /**
   * Efeito para configurar título da página
   */
  useEffect(() => {
    document.title = 'AI Assistance';
  }, []);

  /**
   * Efeito para inserir mensagem de boas-vindas e acionar mensagem inicial
   */
  useEffect(() => {
    // Mensagem de boas vindas
    const welcome = { role: 'assistant', content: 'Olá, em que posso ajudar com Grants hoje?' };
    setMessages([welcome]);

    // Se houve "initialMessage" passada por props, dispara handleSend() com ela
    if (initialMessage) {
      handleSend(initialMessage);
    }
  }, [initialMessage]);

  /**
   * Se quiser manter o histórico de mensagens mesmo que o usuário recarregue a página:
   * useEffect(() => {
   *   const savedHistory = localStorage.getItem('chatHistory');
   *   if (savedHistory) {
   *     setMessages(JSON.parse(savedHistory));
   *   }
   * }, []);
   *
   * useEffect(() => {
   *   localStorage.setItem('chatHistory', JSON.stringify(messages));
   * }, [messages]);
   */

  /**
   * Rola o chat para baixo sempre que as mensagens forem atualizadas
   */
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, loading]);

  /**
   * Função principal de envio de mensagem
   */
  const handleSend = async (customQuery = null) => {
    const userQuery = customQuery || query;
    if (!userQuery.trim()) return;

    // Limpa erro e define o estado de carregando
    setError(null);
    setLoading(true);

    // Adiciona mensagem do usuário no array
    const userMessage = { role: 'user', content: userQuery };
    setMessages(prev => [...prev, userMessage]);
    
    // Zera o input
    setQuery('');

    // Cria fonte de cancelamento da requisição
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      // Exemplo de chamada a um endpoint do FastAPI (ajuste conforme seu backend)
      // Aqui, enviamos "answer" concatenado com eventual "grantId" se houver
      // e passamos "all" e "gpt_response" para sinalizar se queremos somente a lista bruta ou um texto processado
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=conversation`,
        {
          answer: grantId ? `${userQuery} grants id ${grantId}` : userQuery,
          all: all,
          gpt_process: gpt_response
        },
        
      );

      const botMessageContent = response.data.response;

      if (botMessageContent) {
        // Adicionamos a mensagem do bot (vazia) e simulamos a digitação
        const botMessage = { role: 'assistant', content: '' };
        setMessages(prev => [...prev, botMessage]);
        setIsTyping(true);
        simulateTyping(botMessageContent, botMessage);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        setError('Request canceled pela ação do usuário.');
      } else {
        setError('Erro ao obter resposta do servidor.');
      }
      console.error('Error fetching AI response:', err);
      setLoading(false);
      setIsTyping(false);
    }
  };

  /**
   * Função que simula digitação do bot caractere por caractere
   */
  const simulateTyping = (content, botMessage) => {
    setLoading(false);
    let index = 0;

    const interval = setInterval(() => {
      if (index < content.length) {
        botMessage.content += content[index];
        setMessages(prev => [...prev]);
        index++;
      } else {
        clearInterval(interval);
        setIsTyping(false);
      }
    }, 10); // Velocidade de digitação (ms entre caracteres)

    setTypingInterval(interval);
  };

  /**
   * Cancelar a requisição em andamento
   */
  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
      setCancelTokenSource(null);
      setLoading(false);
      setIsTyping(false);
      clearInterval(typingInterval);
      setTypingInterval(null);
    }
  };

  return (
    <div style={styles.container}>
      <style>{globalStyles}</style>

      <h1 style={styles.header}>
        {grantId ? (
          <>
            Tem dúvidas sobre este grant? <span style={styles.highlightedText1}>AI</span>{' '}
            <span style={styles.highlightedText2}>Assistance</span> ajuda você!
          </>
        ) : (
          <>
            Grants <span style={styles.highlightedText1}>AI</span>{' '}
            <span style={styles.highlightedText2}>Assistance</span> para suas necessidades
          </>
        )}
      </h1>

      {/* Área do chat */}
      <div ref={chatBoxRef} style={styles.chatBox}>
        {messages.map((msg, i) => (
          <ChatMessage
            key={i}
            message={msg}
            isUser={msg.role === 'user'}
          />
        ))}

        {/* Se estiver carregando, mostra um “bot digitando” */}
        {loading && (
          <div style={styles.messageContainer}>
            <img src={BotImage} alt="Bot" style={styles.botImage} />
            <div style={{ ...styles.messageBubble, ...styles.loadingMessage }}>
              <img
                src={LoadingIcon}
                alt="Loading"
                style={styles.loadingIcon}
                className="loading"
              />
            </div>
          </div>
        )}

        {error && <p style={styles.error}>{error}</p>}
      </div>

      {/* Barra de input e botões */}
      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          placeholder="Digite sua mensagem"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && !loading && !isTyping && handleSend()}
          disabled={loading || isTyping}
        />
        <button
          onClick={() => handleSend()}
          style={{ ...styles.button, opacity: (loading || isTyping) ? 0.5 : 1 }}
          disabled={loading || isTyping}
        >
          Enviar
        </button>
        {(loading || isTyping) && (
          <button
            onClick={handleCancel}
            style={styles.cancelButton}
          >
            Cancelar
          </button>
        )}
      </div>

      {/* Sugestões de prompts rápidos */}
      <div style={styles.suggestionsContainer}>
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            style={styles.suggestionBubble}
            onClick={() => handleSend(suggestion)}
          >
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImprovedGrantsChatbot;
