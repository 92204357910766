import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import config from '../config';

const ReCaptchaComponent = ({ onChange }) => {
    const recaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true); // Adicionando estado de carregamento

    useEffect(() => {
        if (recaptchaRef.current) {
            recaptchaRef.current.execute(); // Executa automaticamente o reCAPTCHA v3
        }
    }, []);

    const handleRecaptchaChange = async (token) => {
        setIsLoading(true); // Inicia o carregamento do reCAPTCHA
        try {
            if (token) {
                const response = await axios.post(
                    `${config.apiHost}/proxy.php?endpoint=validate_recaptcha`,
                    { recaptchaToken: token }
                );

                if (response.data.success) {
                    console.log('ReCAPTCHA validado com sucesso.');
                    onChange(token); // Atualiza o estado do captchaToken
                } else {
                    console.error('Falha na validação do reCAPTCHA.');
                    onChange(null); // Remove o token inválido
                }
            } else {
                console.error('Token do reCAPTCHA não gerado.');
                onChange(null);
            }
        } catch (error) {
            console.error('Erro ao validar o reCAPTCHA:', error.response?.data || error.message);
            onChange(null);
        } finally {
            setIsLoading(false); // Finaliza o carregamento
        }
    };

    return (
        <div>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.ReCAPTCHA}
                size="invisible"
                onChange={handleRecaptchaChange}
            />
        </div>
    );
};

export default ReCaptchaComponent;
