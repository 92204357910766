// src/page/SubscriptionPage.js

import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import config from '../config';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import Modal from 'react-modal';
import '../assets/css/SubscriptionPage.css';

// Import images directly
import basicIcon from '../images/basic-icon.svg';
import proIcon from '../images/pro-icon.svg';
import enterpriseIcon from '../images/enterprise-icon.svg';
import checkmarkIcon from '../images/checkmark.svg';

// Configure the modal to attach to the root element
Modal.setAppElement('#root');

const SubscriptionPage = () => {
    const { user, logout } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionSuccessMessage, setSubscriptionSuccessMessage] = useState('');

    const initialOptions = {
        'client-id': config.clientId_paypal,
        currency: 'USD',
        intent: 'subscription',
        vault: true,
    };

    const handleSubscriptionSuccess = async (data, planId) => {
        try {
            // Chama o proxy para atualizar a assinatura no backend
            const response = await axios.post(
                `${config.apiHost}/proxy.php?endpoint=subscription`,
                {
                    subscriptionId: data.subscriptionID,
                    userId: user.id,
                    planId: planId,
                    subscribe_id: data.subscriptionID,
                    status: 'active',
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                setSubscriptionSuccessMessage('Subscription updated successfully');
                setIsModalOpen(true);
            } else {
                alert('Failed to update subscription status');
            }
        } catch (error) {
            console.error('Error updating subscription status:', error);
        }
    };

    const closeModalAndRedirect = () => {
        setIsModalOpen(false);
        window.location.href = 'https://devmembers.grants.com/';
    };

    return (
        <PayPalScriptProvider options={initialOptions}>
                 <section id="hero_pricing">
                    <div className="subscription-container">
                        <div className="hero-pricing-wrapper">
                            <h1>Grant Pricing & Membership Information</h1>
                            <p>
                                Explore our extensive database, access AI-powered assistance,
                                and connect with expert consultants. Choose the plan that best
                                fits your needs and start benefiting from our comprehensive
                                services today.
                            </p>
                        </div>
                    </div>
                </section>
            <section id="pricing_plans">
                <div className="subscription-container">
                  
                    <div className="row">
                        
                        {/* Basic Plan */}
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="pricing-card">
                                <img src={basicIcon} alt="basic" />
                                <h3>Basic</h3>
                                <p>Access to our grant database and AI recommendations.</p>
                                <div className="separator-card"></div>
                                <ul className="pricing-features">
                                    <li><img src={checkmarkIcon} alt="check" /> Access to basic database searches</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Limited AI assistance</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Email support</li>
                                </ul>
                                <div className="plans-footer">
                                    <div className="price">$19<span>/mo</span></div>
                                    <PayPalButtons
                                        style={{ layout: "vertical" }}
                                        createSubscription={(data, actions) => {
                                            return actions.subscription.create({
                                                plan_id: config.plan_1_paypal,
                                                subscriber: {
                                                    custom_id: user.id
                                                }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            handleSubscriptionSuccess(data, config.plan_1_paypal);
                                        }}
                                        onError={(err) => {
                                            console.error('Subscription failed: ', err);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Pro Plan */}
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="pricing-card">
                                <img src={proIcon} alt="pro" />
                                <h3>Pro</h3>
                                <p>All Basic features plus premium support and advanced search tools.</p>
                                <div className="separator-card"></div>
                                <ul className="pricing-features">
                                    <li><img src={checkmarkIcon} alt="check" /> Unlimited database searches</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Enhanced AI assistance</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Priority email support</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Access to community forums</li>
                                </ul>
                                <div className="plans-footer">
                                    <div className="price">$45<span>/mo</span></div>
                                    <PayPalButtons
                                        style={{ layout: "vertical" }}
                                        createSubscription={(data, actions) => {
                                            return actions.subscription.create({
                                                plan_id: config.plan_2_paypal,
                                                subscriber: {
                                                    custom_id: user.id
                                                }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            handleSubscriptionSuccess(data, config.plan_2_paypal);
                                        }}
                                        onError={(err) => {
                                            console.error('Subscription failed: ', err);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Enterprise Plan */}
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="pricing-card">
                                <img src={enterpriseIcon} alt="enterprise" />
                                <h3>Enterprise</h3>
                                <p>Comprehensive access including dedicated consultant support and custom AI recommendations.</p>
                                <div className="separator-card"></div>
                                <ul className="pricing-features">
                                    <li><img src={checkmarkIcon} alt="check" /> Unlimited database searches</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Advanced AI assistance</li>
                                    <li><img src={checkmarkIcon} alt="check" /> 24/7 support</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Expert consultants</li>
                                    <li><img src={checkmarkIcon} alt="check" /> Early access to features</li>
                                </ul>
                                <div className="plans-footer">
                                    <div className="price">Contact us for pricing</div>
                                    <a href="/grantsenterprise" className="btn-start">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Success Modal */}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModalAndRedirect}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            padding: '20px',
                            textAlign: 'center',
                            zIndex: '10000',
                            position: 'fixed',
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                            zIndex: '9999',
                        }
                    }}
                >
                    <h2>{subscriptionSuccessMessage}</h2>
                    <p>Your subscription has been successfully updated.</p>
                    <button
                        onClick={closeModalAndRedirect}
                        style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            backgroundColor: '#4A90E2',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                            marginTop: '20px',
                            fontSize: '16px',
                        }}
                    >
                       Please log again!
                    </button>
                </Modal>
            </section>
        </PayPalScriptProvider>
    );
};

export default SubscriptionPage;
