import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import config from '../config';
import {
  Typography,
  CircularProgress,
  Button,
  Box,
  IconButton,
  Divider,
  Paper,
  Chip,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImprovedGrantsChatbot from './ImprovedGrantsChatbot';
import '../assets/css/GrantReport.css';

const GrantDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [grant, setGrant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openChatbot, setOpenChatbot] = useState(false);

  useEffect(() => {
    if (id) {
      fetchGrantDetails(id);
    }
  }, [id]);

  const fetchGrantDetails = async (opportunityId) => {
    try {
      setLoading(true);
      setError(null);

      const response = await axiosInstance.get(
        `${config.ApiHost_User}/api/grants/allopportunities`,
        { params: { opportunity_id: opportunityId } }
      );

      const data = response.data;
      if (data && Array.isArray(data.results) && data.results.length > 0) {
        setGrant(data.results[0]);
      } else if (data.message) {
        setError(data.message);
      } else {
        setError('No data found for this Grant.');
      }
    } catch (err) {
      console.error('Error fetching Grant details:', err);
      setError('An error occurred while fetching the Grant details.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => navigate(-1);
  const handleApply = () => alert('Coming soon');
  const handleOpenChatbot = () => setOpenChatbot(true);
  const handleCloseChatbot = () => setOpenChatbot(false);

  const formatDate = (value) => {
    if (!value) return 'N/A';
    const dt = new Date(value);
    return dt.toLocaleDateString('en-US');
  };

  return (
    <main className="grants-container">
      {loading && (
        <Box className="loading-container">
          <CircularProgress size={80} thickness={4} />
        </Box>
      )}
  
      {!loading && error && (
        <section className="no-data-container">
          <Typography color="error" variant="h6">{error}</Typography>
          <Button variant="contained" color="primary" onClick={handleGoBack}>
            Back
          </Button>
        </section>
      )}
  
      {!loading && !error && grant && (
        <Paper className="grant-report-paper" elevation={6}>
          <IconButton className="close-button" onClick={handleGoBack}>
            <CloseIcon />
          </IconButton>
  
          <header className="report-header">
            <Typography variant="h5">{grant.title || 'No Title'}</Typography>
            {grant.opportunity_id && (
              <Chip label={`ID: ${grant.opportunity_id}`} color="primary" variant="outlined" />
            )}
          </header>
  
          <Divider style={{ margin: '1rem 0' }} />
  
          <section className="report-grid">
            <div>
              <section className="report-section">
                <Typography className="report-section-title">Basic Information</Typography>
                <Typography className="report-field"><strong>Category:</strong> {grant.grant_category || 'N/A'}</Typography>
                <Typography className="report-field"><strong>Subcategory:</strong> {grant.grant_subcategory || 'N/A'}</Typography>
                <Typography className="report-field"><strong>Beneficiary:</strong> {grant.beneficiary || 'N/A'}</Typography>
           
                <Typography className="report-field"><strong>Agency:</strong> {grant.agency_name || 'N/A'}</Typography>
                
              </section>
  
              <section className="report-section">
                <Typography className="report-section-title">Dates</Typography>
                <Typography className="report-field"><strong>Publication Date:</strong> {formatDate(grant.post_date)}</Typography>
                <Typography className="report-field"><strong>Closing Date:</strong> {formatDate(grant.close_date)}</Typography>
                <Typography className="report-field"><strong>Last Updated:</strong> {formatDate(grant.last_updated_date)}</Typography>
                <Typography className="report-field"><strong>Archive Date:</strong> {formatDate(grant.archive_date)}</Typography>
              </section>

              
            </div>
  
            <div>
              <section className="report-section">
                <Typography className="report-section-title">Funding</Typography>
                
                <Typography className="report-field"><strong>Award Ceiling:</strong> {grant.award_ceiling || 'N/A'}</Typography>
                <Typography className="report-field"><strong>Award Floor:</strong> {grant.award_floor || 'N/A'}</Typography>
                <Typography className="report-field"><strong>Total Program Funding:</strong> {grant.estimated_total_program_funding || 'N/A'}</Typography>
              </section>

              <section className="report-section">
            <Typography className="report-section-title">Opportunity Package Details</Typography>
            <Typography className="report-field">
              <strong>Agency Contact Information:</strong> {grant.agency_name || 'N/A'}
            </Typography>
            <Typography className="report-field">
              <strong>Who Can Apply:</strong> Organization Applicants
            </Typography>
            <Typography className="report-field">
              <strong>Package Forms:</strong>
            </Typography>
            <ul className="report-field">
              <li><a href="https://apply07.grants.gov/apply/forms/readonly/SF424_4_0-V4.0.pdf">SF-424 [V4.0]</a></li>
              <li><a href="https://apply07.grants.gov/apply/forms/readonly/ProjectNarrativeAttachments_1_2-V1.2.pdf">Project Narrative Attachment [V1.2]</a></li>
              <li><a href="https://apply07.grants.gov/apply/forms/readonly/SF424A-V1.0.pdf">Budget Information SF-424A [V1.0]</a></li>
              <li><a href="https://apply07.grants.gov/apply/forms/readonly/SF424B-V1.1.pdf">Assurances SF-424B [V1.1]</a></li>
            </ul>
          </section>
            </div>
          </section>
  
          <section className="report-section">
            <Typography className="report-section-title">Description</Typography>
            <Typography className="report-field">{grant.description || 'No description provided.'}</Typography>
            <Box className="report-buttons">
              {grant.url_grantsgov && (
                <Button variant="contained" color="primary" href={grant.url_grantsgov} target="_blank">
                  View More Details on Grants.gov
                </Button>
              )}
              <Button variant="outlined"  onClick={handleApply}>
                Apply
              </Button>

              <Box className="report-buttons2">
             
             <Button
 variant="contained"
 style={{ color: 'white', borderColor: 'white' }}
 onClick={handleOpenChatbot}
>
 AI Assistant
</Button>

 
            </Box>



           
            </Box>

          </section>
  
          <Modal
  open={openChatbot}
  onClose={handleCloseChatbot}
  BackdropProps={{ className: 'modal-backdrop' }}
>
  <Box className="modal-chatbot-container">
    <IconButton onClick={handleCloseChatbot} className="modal-close-button">
      <CloseIcon />
    </IconButton>
    <ImprovedGrantsChatbot grantId={id} />
  </Box>
</Modal>

  
    
        </Paper>
      )}
    </main>
  );
};

export default GrantDetails;
