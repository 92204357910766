/* AuthProvider.jsx */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import config from './config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false); // <-- controle de carregamento
  const navigate = useNavigate();

  const sessionDurationSeconds = 3600; // 1 hora

  // 1) Ao montar, ler do localStorage
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    const storedRefreshToken = localStorage.getItem('refreshToken');

    if (storedAuth) setIsAuthenticated(JSON.parse(storedAuth));
    if (storedUser) {
      // subscription_status forçamos null (será checado pela ProtectedRoute)
      const userData = JSON.parse(storedUser);
      userData.subscription_status = null;
      setUser(userData);
    }
    if (storedToken) setToken(storedToken);
    if (storedRefreshToken) setRefreshToken(storedRefreshToken);

    // Depois de lido tudo, marcamos como concluído
    setInitialLoadDone(true);
  }, []);

  // 2) Verifica se a sessão expirou
  useEffect(() => {
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt) {
      const now = new Date().getTime();
      if (now > parseInt(expiresAt, 10)) {
        console.log('Sessão expirada, efetuando logout.');
        logout(navigate);
      }
    }
  }, [navigate]);

  const login = async (userData, accessToken, refreshTokenValue) => {
    console.log('Realizando login com token:', accessToken);

    const now = new Date().getTime();
    const expiresAt = now + sessionDurationSeconds * 1000;

    const completeUserData = {
      ...userData,
      id: userData.id || 'example-id',
      is_confirmed: userData.is_confirmed,
      subscription_status: null,
    };

    setIsAuthenticated(true);
    setUser(completeUserData);
    setToken(accessToken);
    setRefreshToken(refreshTokenValue);

    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('user', JSON.stringify(completeUserData));
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshTokenValue);
    localStorage.setItem('expires_at', expiresAt.toString());

    // Busca status de assinatura já de imediato (opcional)
    try {
      const response = await axios.get(
        `${config.apiHost}/proxy.php?endpoint=subscription-status`,
        {
          params: { email: completeUserData.email },
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }
      );
      const subscriptionData = response.data;

      // Atualiza estado do usuário com o status de inscrição
      const updatedUserData = {
        ...completeUserData,
        subscription_status: subscriptionData.subscription_status,
      };
      setUser(updatedUserData);
      localStorage.setItem('user', JSON.stringify(updatedUserData));
    } catch (error) {
      console.error('Erro ao checar status de assinatura (login):', error);
    }

    if (completeUserData.is_confirmed === false) {
      // navigate('/login'); // ou algo similar
    }
  };

  const logout = (navigateFunc) => {
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);
    setRefreshToken(null);

    // Limpar LocalStorage
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expires_at');

    // Limpar SessionStorage
    sessionStorage.clear();

    // Limpar todo localStorage, se preferir
    // localStorage.clear();

    // Limpar caches do service worker, se houver
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    navigateFunc('/login');
    window.location.reload(); // força recarregar
  };

  const logout_step = (navigateFunc) => {
    // mesma lógica do logout, mas sem reload automático
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);
    setRefreshToken(null);
    localStorage.clear();
    sessionStorage.clear();

    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
  };

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        `${config.apiHost}/api/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );
      const newToken = response.data.token;
      setToken(newToken);
      localStorage.setItem('token', newToken);
    } catch (error) {
      console.error('Erro ao dar refresh no token:', error);
      logout(navigate);
    }
  };

  return (
    <GoogleOAuthProvider clientId={config.google_client_id}>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          user,
          login,
          logout_step,
          logout: () => logout(navigate),
          token,
          refreshAccessToken,
          initialLoadDone, // <-- exposto no contexto
        }}
      >
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

export const useAuth = () => useContext(AuthContext);
