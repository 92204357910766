import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import GrantsChatbot from './page/GrantsChatbot';
import GrantsPage from './page/GrantsPage';
import LoginPage from './page/LoginPage';
import SignUpPage from './page/SignUpPage';
import MembersArea from './page/MembersArea';
import GrantsTable from './page/GrantsTable';
import Header from './page/Header';
import Footer from './page/Footer';
import GrantDetails from './page/GrantDetails';
import SuggestionForm from './page/SuggestionForm';
import ResetPassword from './page/ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import logo from './assets/logogrants.webp';
import arrow from './assets/arrow.svg';
import ConfirmEmailPage from './page/confirm_email';
import UserPage from './page/UserPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './assets/css/style.css';
import { useAuth } from './AuthContext';
import SubscriptionPage from './page/SubscriptionPage'; // Ensure correct path
import Recomendation_grants from './page/Recomendation_grants'
import AskGrantPage from './page/AskGrantPage';
import AIComponent from './page/ImprovedGrantsChatbot';

const App = () => {
    const location = useLocation();
    const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/signup' ||  location.pathname === '/ResetPassword' ;
  
    return (
        <div className="app-wrapper">
            {!hideHeaderFooter && <Header />}
            
            <main className="content-wrapper">
                <Routes>
                      <Route path="/GrantsChatbot" element={ <GrantsChatbot />} />  

                      <Route path="/Recommendation" element={ <Recomendation_grants />} />   

                      <Route path="/ResetPassword" element={ <ResetPassword /> } />  

                      <Route path="/grants/:id" element={  <GrantDetails /> } />
                      
                      <Route path="/test" element={  <AIComponent /> } />

                      <Route path="/" element={  <ProtectedRoute>   <AskGrantPage />  </ProtectedRoute> } />  
                  

                      {/*  <Route path="/grants/:id" element={  <GrantDetails />  }/>*/}

                      

                        {/*    <Route path="/" element={ <Recomendation_grants />} />*/}
                    
                    <Route path="/Suggestion" element={    <SuggestionForm /> } />
                    {/*    <Route path="/GrantsChatbot" element={ <GrantsChatbot />} />*/}
                    <Route path="/Filter_Grants" element={  <GrantsTable />    } /> 
                  

                    {/* <Route path="/Search_Grants" element={ <GrantsTable />} />*/}

                    <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                    <Route path="/members" element={ <MembersArea />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/user" element={<UserPage />} />
                    <Route path="/subscribe" element={<SubscriptionPage />} />
                </Routes>
            </main>
        
            {!hideHeaderFooter && <Footer />}
        </div>
    );
};

export default App;
