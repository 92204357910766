// src/axiosInstance.js
import axios from 'axios';
import config from './config'; // sua configuração de API

// Função para renovar o token usando o endpoint token_user
export async function refreshAccessTokenHelper() {
  try {
    const userString = localStorage.getItem('user');
    if (!userString) throw new Error('No user found');
    const userData = JSON.parse(userString);
    const userId = userData.id;
    if (!userId) throw new Error('User id not found');

    // Faz chamada para o endpoint token_user
    const response = await axios.post(
      `${config.ApiHost_User}/api/auth/token_user`,
      { user_id: userId }
    );

    // Considerando que a resposta segue o modelo TokenResponse, com o token em access_token
    const newToken = response.data.access_token;

    // Salva o novo token no localStorage
    localStorage.setItem('token', newToken);
    return newToken;
  } catch (error) {
    throw error;
  }
}

// Função para deslogar
export function logoutHelper() {
  // Limpa o localStorage
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expires_at');

  // Exemplo de redirecionamento:
  // window.location.href = '/login';
}

const axiosInstance = axios.create({
  baseURL: config.apiHost, // defina o baseURL conforme necessário
});

// Interceptor para inserir o token em todas as requisições
axiosInstance.interceptors.request.use(
  async (config) => {
    // Se não houver token, tenta obtê-lo automaticamente
    let token = localStorage.getItem('token');
    if (!token) {
      try {
        token = await refreshAccessTokenHelper();
      } catch (error) {
        return Promise.reject(error);
      }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor para lidar com erros de resposta (ex: 401)
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Se receber 401 e ainda não tentamos atualizar antes...
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Tenta renovar o token
        const newToken = await refreshAccessTokenHelper();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (err) {
        // Falha na renovação: deslogar
        logoutHelper();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
