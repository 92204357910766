import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import { useAuth } from './AuthContext';
import config from './config';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, user, logout, initialLoadDone } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  useEffect(() => {
    // Só prossegue se o carregamento inicial do AuthProvider estiver concluído
    if (!initialLoadDone) {
      return;
    }

    // Se não estiver autenticado, faz logout e manda para home (ou /login)
    if (!isAuthenticated) {
      logout();
      return;
    }

    // Se tiver user com email, buscar status
    if (user && user.email) {
      const fetchSubscriptionStatus = async () => {
        try {
          setIsLoading(true);
         // console.log('Buscando status de assinatura para:', user.email);

          const response = await axiosInstance.get(
            `${config.ApiHost_User}/api/subscription-status`,
            {
              params: { email: user.email },
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
              },
            }
          );

          setSubscriptionStatus(response.data.subscription_status);
        } catch (error) {
         // console.error('Erro ao buscar status de assinatura:', error?.response || error);
          // Em caso de erro, forcamos algo diferente de 'active' (null)
          setSubscriptionStatus(null);
        } finally {
          setIsLoading(false);
        }
      };

      fetchSubscriptionStatus();
    } else {
      // Se não tiver user ou email, não há o que buscar
      setIsLoading(false);
    }
  }, [initialLoadDone, isAuthenticated, user, logout]);

  if (!initialLoadDone) {
    // Ainda carregando dados do localStorage? Exiba algo...
    return <div>...</div>;
  }

  if (isLoading) {
    // Esperando resposta do backend
    return <div>...</div>;
  }


    // Se o usuário não estiver autenticado, redireciona para a página de login.
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
  

  // Se a assinatura não for 'active', redirecionamos para "/subscribe"


 // if (subscriptionStatus !== 'active') {
  //  return <Navigate to="/subscribe" />;
  //}

  // Caso contrário, renderiza a rota protegida
  return children;
};

export default ProtectedRoute;
