// src/pages/GrantsSearchPage.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import ClipLoader from 'react-spinners/ClipLoader';
import config from '../config';
import GrantsChatbot from './GrantsChatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import '../assets/css/grantsSearchPage.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

Modal.setAppElement('#root');

const GrantsSearchPage = () => {
  const [filters, setFilters] = useState({
    opened_month: true,
  });
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  // Configura o interceptor do Axios para incluir o token automaticamente
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Limpeza do interceptor quando o componente desmonta
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

 
  // Função para inicializar a busca com token
  const initializeSearch = async () => {
    setLoading(true);
    setError(null);

    try {
     
      // Realiza a busca inicial com os filtros e token
      const response = await axiosInstance.get(`${config.ApiHost_User}/api/grants/allopportunities`, {
        // Removido o cabeçalho manual de Authorization
        params: {
          page: currentPage,
          limit: 10,
          ...filters,
        },
      });
      setResults(response.data.results || []);
      setTotalPages(Math.ceil(response.data.total_results / 10));
    //  console.log('Search results fetched:', response.data.results);
    } catch (err) {
      console.error('Error fetching data:', err.response?.data || err);
      
    } finally {
      setLoading(false);
    }
  };

  // useEffect para inicializar a busca quando o componente monta ou quando currentPage/filters mudam
  useEffect(() => {
    initializeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  // Função para realizar a busca baseada na query
  const handleSearch = async () => {
    if (!query.trim()) return;
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post(
        `${config.ApiHost_User}/api/conversation`,
        {
          answer: query,
          all: true,
        }
      );
      setResults(response.data.results || []);
      setTotalPages(Math.ceil(response.data.total_results / 10));
     // console.log('Search query results:', response.data.results);
    } catch (err) {
      console.error('Error fetching data:', err.response?.data || err);
    
    } finally {
      setLoading(false);
    }
  };

  // Função para lidar com a tecla Enter na busca
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(1);
      handleSearch();
    }
  };

  // Função para lidar com mudanças nos filtros
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setCurrentPage(1);
  };

  // Funções para abrir e fechar o modal de detalhes do grant
  const openModal = (result) => {
    setModalContent(result);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Função para alternar a visibilidade dos filtros
  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  // Função para buscar sugestões de pesquisa
  const fetchSuggestions = async (input) => {
    try {
      const response = await axiosInstance.get(`${config.ApiHost_User}/api/suggestions`, {
        params: { query: input },
      });
      setSuggestions(response.data.suggestions || []);
     // console.log('Suggestions fetched:', response.data.suggestions);
    } catch (err) {
      console.error('Error fetching suggestions:', err);
    }
  };

  // Função para lidar com mudanças na query de busca
  const handleQueryChange = (e) => {
    const input = e.target.value;
    setQuery(input);
    if (input.length > 2) {
      fetchSuggestions(input);
    } else {
      setSuggestions([]);
    }
  };

  // Função para lidar com cliques em sugestões
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    handleSearch();
  };

  // Função para lidar com mudanças de página na paginação
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="grants-search-page">
      <header className="header">
        <h1>Find the Perfect Grant for You</h1>
        <div className="search-bar">
          <input
            ref={searchInputRef}
            type="text"
            value={query}
            onChange={handleQueryChange}
            onKeyPress={handleKeyPress}
            placeholder="Search for grants..."
            aria-label="Search grants"
          />
          <button onClick={handleSearch} aria-label="Search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
          {Array.isArray(suggestions) && suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, idx) => (
                <li key={idx} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button className="filter-toggle" onClick={toggleFilters}>
          <FontAwesomeIcon icon={faFilter} />
          {filtersVisible ? 'Hide Filters' : 'Show Filters'}
        </button>
      </header>

      {filtersVisible && (
        <section className="filters-section">
          <div className="filters-container">
            <div className="filter-item">
              <label htmlFor="agency_name">Agency Name</label>
              <input
                type="text"
                name="agency_name"
                value={filters.agency_name || ''}
                onChange={handleFilterChange}
                id="agency_name"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="posted_date">Posted Date</label>
              <input
                type="date"
                name="posted_date"
                value={filters.posted_date || ''}
                onChange={handleFilterChange}
                id="posted_date"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="close_date">Close Date</label>
              <input
                type="date"
                name="close_date"
                value={filters.close_date || ''}
                onChange={handleFilterChange}
                id="close_date"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="category_of_funding_activity">Funding Category</label>
              <input
                type="text"
                name="category_of_funding_activity"
                value={filters.category_of_funding_activity || ''}
                onChange={handleFilterChange}
                id="category_of_funding_activity"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="eligible_applicants">Eligible Applicants</label>
              <input
                type="text"
                name="eligible_applicants"
                value={filters.eligible_applicants || ''}
                onChange={handleFilterChange}
                id="eligible_applicants"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="award_ceiling">Award Ceiling</label>
              <input
                type="number"
                name="award_ceiling"
                value={filters.award_ceiling || ''}
                onChange={handleFilterChange}
                id="award_ceiling"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="award_floor">Award Floor</label>
              <input
                type="number"
                name="award_floor"
                value={filters.award_floor || ''}
                onChange={handleFilterChange}
                id="award_floor"
              />
            </div>
            <div className="filter-checkboxes">
              <label>
                <input
                  type="checkbox"
                  name="closing_this_month"
                  checked={filters.closing_this_month || false}
                  onChange={handleFilterChange}
                />
                Closing This Month
              </label>
              <label>
                <input
                  type="checkbox"
                  name="opened_today"
                  checked={filters.opened_today || false}
                  onChange={handleFilterChange}
                />
                Opened Today
              </label>
              <label>
                <input
                  type="checkbox"
                  name="opened_month"
                  checked={filters.opened_month || false}
                  onChange={handleFilterChange}
                />
                Opened This Month
              </label>
            </div>
          </div>
        </section>
      )}

      <main className="results-section">
        {loading ? (
          <div className="loader">
            <ClipLoader color="#1BBCEA" loading={loading} size={50} />
          </div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : Array.isArray(results) && results.length > 0 ? (
          <>
            <div className="results-container">
              {results.map((result, index) => (
                <div className="result-card" key={index}>
                  <h3>{result.title}</h3>
                  <p><strong>Agency:</strong> {result.agency_name}</p>
                  <p><strong>Posted:</strong> {new Date(result.post_date).toLocaleDateString()}</p>
                  <button onClick={() => openModal(result)}>View Details</button>
                </div>
              ))}
            </div>
            <div className="pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </>
        ) : (
          <div className="no-results">No grants found. Try adjusting your search or filters.</div>
        )}
      </main>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="grant-modal"
        overlayClassName="modal-overlay"
        contentLabel="Grant Details"
      >
        <button className="modal-close-button" onClick={closeModal} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {modalContent ? (
          <div className="modal-content">
            <h2>{modalContent.title}</h2>
            <div className="modal-details">
              <p><strong>Agency:</strong> {modalContent.agency_name}</p>
              <p><strong>Posted Date:</strong> {new Date(modalContent.post_date).toLocaleDateString()}</p>
              <p><strong>Close Date:</strong> {modalContent.close_date ? new Date(modalContent.close_date).toLocaleDateString() : 'N/A'}</p>
              <div className="modal-description">
                <ReactMarkdown>
                  {modalContent.description || 'No description available.'}
                </ReactMarkdown>
              </div>
              <p>
                <a href={modalContent.url_grantsgov} target="_blank" rel="noopener noreferrer">
                  More Information
                </a>
              </p>
            </div>
            <GrantsChatbot grantId={modalContent.opportunity_id} />
          </div>
        ) : (
          <div className="loader">
            <ClipLoader color="#1BBCEA" loading={true} size={50} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default GrantsSearchPage;
