import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, TextField } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { redirect, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import config from '../config';
import '../assets/css/LoginPage.css';

const LoginPage = ({ onLoginSuccess }) => {
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  // Estados para login
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // Estados para confirmação de conta
  const [showConfirmationField, setShowConfirmationField] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  /**
   * Realiza login com email e senha via proxy.
   * Se a conta não estiver confirmada, exibe o campo de confirmação.
   */
  const handleLogin = async () => {
    if (email.trim() === '' || password.trim() === '') {
      setMessage('Please fill in both email and password');
      return;
    }

    setIsLoggingIn(true);
    setMessage('');

    try {
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=login`,
        { email, password }
      );
      const data = response.data;
      // Salva os dados do usuário no AuthContext
      login({ email: data.email, id: data.id, is_confirmed: data.is_confirmed });
      setMessage('Login successful!');
      redirect("/")
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail === 'Account is not confirmed'
      ) {
        setMessage('Your account is not confirmed. Please enter the code we sent to your email.');
        setShowConfirmationField(true);
      } else {
        if (error.response && error.response.data && error.response.data.detail) {
          setMessage(`Login failed: ${error.response.data.detail}`);
        } else {
          setMessage(`Login failed: ${error.message}`);
        }
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  /**
   * Envia o código de confirmação para a API, via proxy.
   */
  const handleConfirmEmail = async () => {
    setIsConfirming(true);
    setMessage('');
    try {
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=confirm_email`,
        {
          email,
          confirmation_code: confirmationCode
        }
      );
      const data = response.data;
      if (data.success) {
        setMessage('Email confirmed successfully! You can now log in.');
        setShowConfirmationField(false);
       
      } else {
        setMessage(`Confirmation failed: ${data.detail || 'Invalid code'}`);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setMessage(`Confirmation failed: ${error.response.data.detail}`);
      } else {
        setMessage(`Confirmation failed: ${error.message}`);
      }
    } finally {
      setIsConfirming(false);
    }
  };

  /**
   * Lida com o sucesso do login via Google, usando o proxy.
   */
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    setIsLoggingIn(true);
    setMessage('');

    try {
      const { credential } = credentialResponse;
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=callback`,
        { token: credential }
      );
      const data = response.data;
      login({ email: data.email, id: data.id }, data.token, data.refresh_token);
      setMessage('Google login successful!');
      onLoginSuccess();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setMessage(`Google login failed: ${error.response.data.detail}`);
      } else {
        setMessage(`Google login failed: ${error.message}`);
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  /**
   * Lida com a falha no login via Google.
   */
  const handleGoogleLoginFailure = () => {
    setMessage('Google login failed. Please try again.');
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-form">
          <h2 className="login-title">Sign In</h2>
          <p className="login-subtitle">Please sign in to access your account.</p>

          {!showConfirmationField && (
            <>
              <TextField
                className="login-input"
                type="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    backgroundColor: 'white'
                  }
                }}
              />
              <TextField
                className="login-input"
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                    backgroundColor: 'white'
                  }
                }}
              />

              {isLoggingIn ? (
                <CircularProgress color="primary" style={{ marginTop: '20px' }} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    fullWidth
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    Sign In
                  </Button>
                  <center>


                  
                
<GoogleLogin
  onSuccess={handleGoogleLoginSuccess}
  onError={handleGoogleLoginFailure}
  className="google-login-button"
  style={{ marginTop: '20px', paddingTop: '20px' }}
/>


                  
                   </center>   <br /> <br />



                </>
              )}  
            </>
          )}
            



          {showConfirmationField && (
            <div style={{ marginTop: '20px' }}>
              <p>Please enter your confirmation code:</p>
              <TextField
                className="login-input"
                type="text"
                label="Confirmation Code"
                variant="outlined"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                fullWidth
                margin="normal"
              />
              {isConfirming ? (
                <CircularProgress color="primary" style={{ marginTop: '20px' }} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmEmail}
                  fullWidth
                  style={{ marginTop: '20px' }}
                >
                  Confirm Email
                </Button>
              )}
            </div>








          )}


<p className="register-link">
            Don't have an account? <a href="/signup">Signup Here</a>
            </p>

            <p className="register-link">
            Reset Password <a href="/ResetPassword">Click Here</a>
            </p>

            <p className="login-message">{message}</p>

        </div>

        
      </div>
      
    </div>
  );
};

export default LoginPage;
