// SuggestionForm.js
import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../AuthContext';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';

const SuggestionForm = () => {
  const { user } = useAuth(); // Obtemos informações do usuário autenticado
  const [suggestionText, setSuggestionText] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  // Função para obter o token (pode ser importada se já existir)
  const fetchToken = async () => {
    const email = config.default_user_email;
    const password = config.defaultUserPassword;
    try {
      const response = await axios.post(`${config.apiHost}/api/auth/token`, {
        email,
        password,
      });
      const { access_token } = response.data;
      localStorage.setItem('access_token', access_token);
      return access_token;
    } catch (error) {
      console.error('Error fetching token:', error.response?.data || error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    // Obter token
    const token = await fetchToken();
    if (!token) {
      setError('Authentication failed. Please try again.');
      setLoading(false);
      return;
    }

    try {
      // Preparar payload com email do usuário e sugestão
      const payload = {
        email: user.email, // Supomos que useAuth fornece informações do usuário atual
        suggestion_text: suggestionText,
      };

      const response = await axios.post(
        `${config.apiHost}/api/improvements`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage('Suggestion successfully submitted.');
        setSuggestionText(''); // Limpa o campo após sucesso
      } else {
        setError('Failed to submit suggestion.');
      }
    } catch (err) {
      console.error('Error submitting suggestion:', err);
      setError('Error submitting your suggestion. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 600, margin: '0 auto' }}>
      <Typography variant="h6" gutterBottom>
        Submit a Suggestion for Improvement
      </Typography>
      <TextField
        label="Your Suggestion"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={suggestionText}
        onChange={(e) => setSuggestionText(e.target.value)}
        required
        margin="normal"
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Button type="submit" variant="contained" color="primary">
          Submit Suggestion
        </Button>
      )}
      {message && (
        <Typography variant="body1" color="primary" style={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
      {error && (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
    </form>
  );
};

export default SuggestionForm;
