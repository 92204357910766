import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CircularProgress,
  Chip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import axiosInstance from '../axiosInstance';
import config from '../config';
import { Mic, Stop, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { RecordRTCPromisesHandler } from 'recordrtc';
import womanImage from '../assets/grants.png';

// ---------- UTILITÁRIO PARA CONVERTER BLOB EM BASE64 ----------
async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
}

// ---------- PAGE CONTAINER ----------
const PageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '50vh',
  backgroundColor: '#f9fbfd',
}));

// ---------- HERO SECTION ----------
const HeroWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5, 10),
  },
}));

const HeroGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  alignItems: 'center',
}));

// ---------- LEFT CONTENT ----------
const LeftContent = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.4rem',
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  lineHeight: 1.5,
  marginBottom: theme.spacing(3),
  color: '#666',
}));

// ---------- SEARCH FORM ----------
const SearchForm = styled('form')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const SearchInput = styled(TextField)(() => ({
  flex: 1,
  maxWidth: 320,
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px 0 0 30px',
  },
}));

const SearchButton = styled(Button)(() => ({
  backgroundColor: '#fc8972',
  color: '#fff',
  padding: '12px 24px',
  border: 'none',
  borderRadius: '0 30px 30px 0',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  textTransform: 'none',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: '#006fd0',
  },
  '&:disabled': {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
}));

const MicButton = styled(IconButton)(() => ({
  borderRadius: 0,
  backgroundColor: '#fff',
  color: '#1EB7EC',
  border: '1px solid #ccc',
  borderLeft: 'none',
  borderRight: 'none',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

// ---------- HERO IMAGE ----------
const HeroImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '500px',
  display: 'block',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    margin: '0',
  },
}));

// ---------- SUGGESTIONS SECTION ----------
const SuggestionsSection = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  padding: theme.spacing(3, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3, 10),
  },
}));

const SuggestionsToggle = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: '#1EB7EC',
  color: '#fff',
  borderRadius: '30px',
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#006fd0',
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#f7f9fc',
  marginBottom: theme.spacing(2),
  boxShadow: 'none',
  border: '1px solid #e0e0e0',
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#e3f2fd',
  borderBottom: '1px solid #ccc',
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(1, 0),
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  padding: '16px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
}));

const SuggestionButton = styled(Button)(() => ({
  backgroundColor: '#f2f2f2',
  color: '#333',
  borderRadius: '20px',
  textTransform: 'none',
  fontWeight: 500,
  padding: '6px 16px',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));

// ---------- RESULTS SECTION ----------
const ResultsSection = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 2),
  backgroundColor: '#f9fbfd',
}));

const IntroText = styled(Typography)(() => ({
  marginBottom: '20px',
  fontStyle: 'italic',
  color: '#555',
  textAlign: 'center',
}));

// ---------- LOADING OVERLAY ----------
const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.3)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ---------- RECORDING OVERLAY & ANIMAÇÃO ----------
const RecordingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  backdropFilter: 'blur(4px)',
  zIndex: 10000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const PulsatingMicIcon = styled(Mic)(({ theme }) => ({
  color: '#fff',
  fontSize: '4rem',
  animation: `${pulse} 1.5s infinite ease-in-out`,
}));

// ====== COMPONENTE ======
const ChatBot = () => {
  const [grants, setGrants] = useState([]);
  const [textResponse, setTextResponse] = useState('');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [introText, setIntroText] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [responseType, setResponseType] = useState(''); // novo estado para diferenciar tipos

  // Estado para paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  // Verifica se o navegador suporta MediaRecorder (nativo)
  const [useNativeMediaRecorder, setUseNativeMediaRecorder] = useState(false);
  useEffect(() => {
    if ('MediaRecorder' in window) {
      setUseNativeMediaRecorder(true);
    }
  }, []);

  // Estado e refs de gravação
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const recordRTCRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(5);
  const audioRecording = useRef(false);
  const resultsRef = useRef(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [grants]);

  useEffect(() => {
    if (!loading && (grants.length > 0 || textResponse)) {
      resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading, grants, textResponse]);

  // Função para tratar a resposta da API
  const handleApiResponse = (response) => {
    if (response && response.data) {
      const apiResponse = response.data;
      // Se a resposta for do tipo resumo, exibe de forma diferenciada
      if (apiResponse.tipo === 'resumo') {
        setTextResponse(`Resumo: ${apiResponse.valor}`);
        setResponseType('resumo');
        setGrants([]);
      } else if (apiResponse.resultados) {
        setGrants(apiResponse.resultados);
        setTextResponse('');
        setResponseType('lista');
      } else if (apiResponse.raw_text) {
        setTextResponse(apiResponse.raw_text);
        setGrants([]);
        setResponseType('texto');
      } else if (apiResponse.mensagem) {
        setTextResponse(apiResponse.mensagem);
        setGrants([]);
        setResponseType('texto');
      } else {
        setTextResponse("Estrutura de resposta inesperada: " + JSON.stringify(apiResponse));
        setGrants([]);
        setResponseType('texto');
      }
    }
  };

  // Envio de áudio como base64
  const sendAudioAsBase64 = async (base64Audio) => {
    setLoading(true);
    setError('');
    setGrants([]);
    setTextResponse('');
    setIntroText('');
    try {
      const response = await axiosInstance.post(
        `${config.ApiHost_User}/api/grants/ask_query`,
        {
          question: query,
          audio_b64: base64Audio,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log(response);
      handleApiResponse(response);
    } catch (err) {
      console.error('Erro ao enviar áudio base64:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Envio de texto (sem áudio)
  const handleSearchText = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError('');
    setGrants([]);
    setTextResponse('');
    setIntroText('');
    try {
      const response = await axiosInstance.post(
        `${config.ApiHost_User}/api/grants/ask_query`,
        { question: query },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log(response);
      handleApiResponse(response);
    } catch (err) {
      console.error('Erro ao enviar texto:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Iniciar gravação
  const startRecording = async () => {
    try {
      setIsRecording(true);
      audioRecording.current = true;
      setTimeLeft(5);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (useNativeMediaRecorder) {
        let localChunks = [];
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localChunks.push(event.data);
          }
        };
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(localChunks, { type: 'audio/webm' });
          const base64Audio = await blobToBase64(audioBlob);
          await sendAudioAsBase64(base64Audio);
        };
        mediaRecorderRef.current.start();
      } else {
        recordRTCRef.current = new RecordRTCPromisesHandler(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
        });
        await recordRTCRef.current.startRecording();
      }
      countdownIntervalRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error('Erro ao acessar microfone:', error);
      setError('Erro ao acessar microfone.');
      setIsRecording(false);
      audioRecording.current = false;
    }
  };

  // Parar gravação
  const stopRecording = async () => {
    setIsRecording(false);
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }
    if (useNativeMediaRecorder && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    } else if (recordRTCRef.current) {
      await recordRTCRef.current.stopRecording();
      const blob = await recordRTCRef.current.getBlob();
      const base64Audio = await blobToBase64(blob);
      await sendAudioAsBase64(base64Audio);
    }
  };

  // Função principal de busca (Enter)
  const handleSearch = async (e) => {
    e.preventDefault();
    if (!audioRecording.current) {
      handleSearchText(null);
    }
  };

  // Sugestões
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
  };

  // Helper para checar data de fechamento
  const isGrantOpen = (closeDate) => {
    if (!closeDate) return null;
    const now = new Date();
    const close = new Date(closeDate);
    return close > now;
  };

  // Paginação
  const totalPages = Math.ceil(grants.length / itemsPerPage);
  const currentGrants = grants.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <PageContainer>
      {/* HERO */}
      <HeroWrapper>
        <HeroGrid container spacing={4}>
          <Grid item xs={12} md={6}>
            <LeftContent>
              <HeroTitle variant="h2">
                <span style={{ color: '#1EB7EC' }}>AI Beta Search</span>
              </HeroTitle>
              <HeroTitle variant="h2">
                Find the <span style={{ color: '#FC8972' }}>Perfect Grant</span> for Your Needs
              </HeroTitle>
              <HeroSubtitle variant="body1">
                Explore our extensive database and discover the ideal funding opportunity.
                Feel free to ask about categories, deadlines, or any specific topics.
              </HeroSubtitle>
              <SearchForm onSubmit={handleSearchText}>
                <SearchInput
                  variant="outlined"
                  placeholder="Ask about grants..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <MicButton
                  onClick={() => {
                    if (!isRecording) {
                      startRecording();
                    } else {
                      stopRecording();
                    }
                  }}
                  disabled={loading}
                >
                  {isRecording ? <Stop /> : <Mic />}
                </MicButton>
                <SearchButton type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Search'}
                </SearchButton>
              </SearchForm>
            </LeftContent>
          </Grid>
          <Grid item xs={12} md={6}>
            <HeroImage src={womanImage} alt="Woman with phone" />
          </Grid>
        </HeroGrid>
      </HeroWrapper>
      {/* SUGESTÕES */}
      <SuggestionsSection>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Need help? Select a search suggestion or browse our categories
        </Typography>
        <Box display="flex" justifyContent="center">
          <SuggestionsToggle onClick={() => setShowSuggestions(!showSuggestions)} variant="contained">
            {showSuggestions ? 'Hide Tips & Categories' : 'View Tips & Categories'}
          </SuggestionsToggle>
        </Box>
        {showSuggestions && (
          <Box sx={{ mt: 3 }}>
            <AccordionStyled disableGutters>
              <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>Quick Tips</Typography>
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <SuggestionButton onClick={() => handleSuggestionClick('grants open this month')}>
                  Grants open this month
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('Grants open in September 2024')}>
                  Grants open in September 2024
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('grants that paid the most in january 2025')}>
                  grants that paid the most in january 2025
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('grants for education')}>
                  Grants for education
                </SuggestionButton>
              </AccordionDetailsStyled>
            </AccordionStyled>
            <AccordionStyled disableGutters>
              <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>Opportunity Categories</Typography>
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <SuggestionButton onClick={() => handleSuggestionClick('category Education & Training Grants')}>
                  Grants category Education & Training
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('category Arts & Environmental Grants')}>
                  Grants category Arts & Environmental
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('category Health & Wellness Grants')}>
                  Grants category Health & Wellness
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('category Housing & Community Development')}>
                  Grants category Housing & Community Dev.
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('category Business Grants')}>
                  Grants category Business
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('category Individual Grants')}>
                  Grants category Individual
                </SuggestionButton>
              </AccordionDetailsStyled>
            </AccordionStyled>
            <AccordionStyled disableGutters>
              <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>More question ideas</Typography>
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <SuggestionButton onClick={() => handleSuggestionClick('latest grants available')}>
                  Latest Grants
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('grants for startups')}>
                  Grants for Startups
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('grants for AI research')}>
                  Grants for AI Research
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('military veterans grants')}>
                  Military Veterans
                </SuggestionButton>
                <SuggestionButton onClick={() => handleSuggestionClick('drug prevention grants')}>
                  Drug Prevention
                </SuggestionButton>
              </AccordionDetailsStyled>
            </AccordionStyled>
          </Box>
        )}
      </SuggestionsSection>
      {/* ERRO */}
      {error && (
        <Typography variant="h6" color="error" textAlign="center" mb={2}>
          {error}
        </Typography>
      )}
      {/* RESULTADOS */}
      {(textResponse || grants.length > 0) && (
        <ResultsSection ref={resultsRef}>
          {introText && <IntroText variant="subtitle1">{introText}</IntroText>}
          {textResponse && (
            <Box
              sx={{
                backgroundColor: '#f2f8ff',
                border: '1px solid #f9fbfd',
                borderRadius: '8px',
                padding: 3,
                marginBottom: 3,
                color: '#333',
                lineHeight: 1.6,
                maxWidth: '1200px',
                margin: '0 auto',
              }}
            >
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {textResponse}
              </Typography>
            </Box>
          )}
          {grants.length > 0 && (
            <>
              <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
                <Grid container spacing={3}>
                  {currentGrants.map((grant, index) => {
                    const openStatus = isGrantOpen(grant.close_date);
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                          sx={{
                            width: '100%',
                            minHeight: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundColor: '#e3f2fd',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                            '&:hover': {
                              transform: 'translateY(-3px)',
                              boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
                            },
                          }}
                        >
                          <CardHeader
                          title={
                            <Typography variant="h6" component="div" sx={{ fontSize: '0.9em' }}>
                              {grant.title || 'Untitled Grant'}
                            </Typography>
                          }
                          
                            subheader={
                              <Box display="flex" alignItems="center" mt={1}>
                                <Chip
                                  label={openStatus === null ? 'Unknown' : openStatus ? 'Open' : 'Closed'}
                                  color={openStatus ? 'success' : 'error'}
                                  size="small"
                                  sx={{ fontWeight: 600 }}
                                />
                                <Typography variant="caption" color="textSecondary" sx={{ marginLeft: 1 }}>
                                  {grant.opportunity_id ? `#${grant.opportunity_id}` : ''}
                                </Typography>
                              </Box>
                            }
                          />
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              <strong>Post Date:</strong>{' '}
                              {grant.post_date ? new Date(grant.post_date).toLocaleDateString('en-US') : 'Not available'}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              <strong>Close Date:</strong>{' '}
                              {grant.close_date ? new Date(grant.close_date).toLocaleDateString('en-US') : 'Not available'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Agency:</strong> {grant.agency_name || 'Unknown'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Category:</strong> {grant.grant_category || 'Unknown'}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              variant="contained"
                              onClick={() =>
                                window.open(`${window.location.origin}/grants/${grant.opportunity_id}`, '_blank')
                              }
                              sx={{
                                margin: '0 auto',
                                textTransform: 'none',
                                borderRadius: '20px',
                              }}
                            >
                              More Detail
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              )}
            </>
          )}
        </ResultsSection>
      )}
      {/* OVERLAY DE GRAVAÇÃO */}
      {isRecording && (
        <RecordingOverlay>
          <Box display="flex" flexDirection="column" alignItems="center">
            <PulsatingMicIcon />
            <Typography variant="h4" sx={{ color: '#fff', mt: 2 }}>
              {timeLeft}s
            </Typography>
          </Box>
        </RecordingOverlay>
      )}
      {/* LOADING OVERLAY */}
      {loading && (
        <LoadingOverlay>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={48} sx={{ color: '#fff' }} />
            <Typography variant="h6" sx={{ marginTop: 2, color: '#fff' }}>
              Loading...
            </Typography>
          </Box>
        </LoadingOverlay>
      )}
    </PageContainer>
  );
};

export default ChatBot;
