import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logogrants.webp';
import arrow from '../assets/btn-arrow.svg';
import '../assets/css/header.css'; // Novo estilo de header
import { useAuth } from '../AuthContext'; // Certifique-se de ter esse import

const Header = () => {
  const { isAuthenticated, logout } = useAuth(); // Use seu hook personalizado
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const location = useLocation();

  // Função para abrir/fechar o menu hambúrguer em telas menores
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Função para abrir/fechar o dropdown de perfil
  const toggleProfileMenu = () => {
    setProfileOpen(!profileOpen);
  };

  // Pega dados do localStorage
  const storedUser = localStorage.getItem('user');
  let userEmail = '';
  if (storedUser) {
    try {
      const userObj = JSON.parse(storedUser);
      userEmail = userObj.email || '';
    } catch (error) {
      console.error('Erro ao fazer parse do user no localStorage:', error);
    }
  }

  return (
    <header className="header-new">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
        {/* Botão de menu hambúrguer para telas menores */}
        <button className="menu-toggle" onClick={toggleMenu}>
          {/* Use classes CSS para criar os ícones */}
          <div className={`menu-icon ${menuOpen ? 'open' : ''}`}></div>
        </button>
      </div>

      <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/">AI Search</Link>
        <Link to="/Recommendation">Recomendation grants</Link>

        {/* 
          Exemplo de links comentados:
          <Link to="/GrantsChatbot">GrantsChatbot</Link>
          <Link to="/Filter_Grants">Filter Grants</Link>
          <Link to="/ai-assistance">AI Assistance</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/grant-database">Grant Database</Link>
          <Link to="/blog">Blog</Link>
        */}

        {isAuthenticated ? (
          <div className="profile-container">
            {/* Botão que abre o dropdown */}
            <button onClick={toggleProfileMenu} className="profile-button">
              Profile
            </button>
            
            {/* Dropdown que exibe email e logout */}
            {profileOpen && (
              <div className="profile-dropdown">
                <p> User: {userEmail}</p>
     
                <button onClick={logout} className="logout-button">
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Link to="/" className="btn-arrow">
              Login <img src={arrow} alt="arrow" />
            </Link>
            <Link to="/signup">Sign Up</Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
