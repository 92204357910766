import React, { useState } from 'react';
import axios from 'axios';
import '../assets/css/LoginPage.css';
import config from '../config';
import ReCaptchaComponent from './ReCaptchaComponent';
import { useNavigate } from 'react-router-dom';

const RequestPasswordReset = ({ setLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [stage, setStage] = useState('request'); // 'request', 'reset' ou 'success'
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Estado para lidar com o token e o carregamento do reCAPTCHA
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);

  const navigate = useNavigate();

  // Função chamada quando o reCAPTCHA é completado com sucesso
  const handleRecaptchaChange = (value) => {
    setCaptchaToken(value);
    setIsRecaptchaLoading(false);
  };

  // Etapa 1: Solicitar código de redefinição
  const handleRequestReset = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    if (!captchaToken) {
      setMessage('Please complete the reCAPTCHA first.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=request_password_reset`,
        {
          email,
          recaptchaToken: captchaToken,
        }
      );
      setMessage(response.data.message || 'A reset code has been sent to your email.');
      setStage('reset'); // Avança para a etapa de redefinição de senha
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail || error.message || 'Failed to send reset code.';
      setMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Etapa 2: Redefinir senha
  const handleResetPassword = async () => {
    if (!resetCode || !newPassword || !confirmPassword) {
      setMessage('All fields are required.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    if (newPassword.length < 8) {
      setMessage('Password must be at least 8 characters long.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiHost}/proxy.php?endpoint=reset_password`,
        {
          email,
          reset_code: resetCode,
          new_password: newPassword,
        }
      );
      setMessage(response.data.message || 'Password reset successfully!');
      setStage('success'); // Etapa final: sucesso
      setLoginSuccess(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail || error.message || 'Failed to reset password.';
      setMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Função para voltar ao login
  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-form">
          {/* Etapa 1: Solicitação do Código */}
          {stage === 'request' && (
            <>
              <h2 className="login-title">Request Password Reset</h2>
              <p className="login-subtitle">
                Enter your email address below to receive a reset code.
              </p>
              <input
                className="login-input"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Componente ReCaptcha */}
              <ReCaptchaComponent onChange={handleRecaptchaChange} />

              {/* Botão de envio habilitado apenas se o reCAPTCHA foi validado */}
              {captchaToken && (
                <button
                  className="login-button"
                  onClick={handleRequestReset}
                  disabled={loading}
                >
                  {loading ? 'Sending...' : 'Send Reset Code'}
                </button>
              )}

              {/* Mensagens condicionais para o reCAPTCHA */}
              {!captchaToken && isRecaptchaLoading && (
                <p style={{ color: '#999', fontWeight: 'bold' }}>
                  Loading reCAPTCHA...
                </p>
              )}

              {!captchaToken && !isRecaptchaLoading && (
                <p style={{ color: '#999', fontWeight: 'bold' }}>
                  Please complete the reCAPTCHA to continue.
                </p>
              )}

                        
<p className="register-link">
  Back to Login <a href="/login">Click Here</a>
</p>
            </>
          )}

          {/* Etapa 2: Redefinição de Senha */}
          {stage === 'reset' && (
            <>
              <h2 className="login-title">Reset Password</h2>
              <p className="login-subtitle">
                Enter the reset code and your new password.
              </p>
              <input
                className="login-input"
                type="text"
                placeholder="Enter reset code"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />
              <input
                className="login-input"
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                className="login-input"
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                className="login-button"
                onClick={handleResetPassword}
                disabled={loading}
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>
              <p className="login-message">{message}</p>
            </>
          )}

          {/* Etapa Final: Sucesso */}
          {stage === 'success' && (
            <>
              <h2 className="login-title">Password Reset Successfully</h2>
              <p className="login-message">
                Your password has been reset. You can now log in with your new password.
              </p>

            
              <p className="register-link">
  Back to Login <a href="/login">Click Here</a>
</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
