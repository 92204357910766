import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.js';
import { GoogleLogin } from '@react-oauth/google'; // Componente de login do Google
import '../assets/css/LoginPage.css';
import config from '../config';
import ReCaptchaComponent from './ReCaptchaComponent'; // Componente reutilizável do reCAPTCHA

const SignUpPage = ({ onLoginSuccess }) => {
    const { login } = useAuth();
    const navigate = useNavigate();

    // Estados para dados de cadastro
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [message, setMessage] = useState('');

    // Estados para controle de UI e funcionalidades extras
    const [showPassword, setShowPassword] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [showConfirmationField, setShowConfirmationField] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);

    // Funções de validação simples
    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
    const validatePassword = (password) => password.length >= 8;

    /**
     * Realiza o cadastro do usuário via proxy.
     */
    const handleSignUp = async () => {
        if (!validateEmail(email)) {
            setMessage('Invalid email format');
            return;
        }
        if (!validatePassword(password)) {
            setMessage('Password must be at least 8 characters long');
            return;
        }
        if (password !== repeatPassword) {
            setMessage('Passwords do not match');
            return;
        }

        setIsRegistering(true);
        try {
            const response = await axios.post(
                `${config.apiHost}/proxy.php?endpoint=register`,
                { name, email, password }
            );
            const data = response.data;

            if (data.message === 'Registration successful. Please check your email to confirm your account.') {
                setMessage('Registration successful. Please check your email to confirm your account.');
                setShowConfirmationField(true);
            } else {
                // Se não for necessário confirmar, efetua login automaticamente
                login({ email: data.email, id: data.id }, data.token, data.refresh_token);
                setMessage('Sign up successful!');
               
                navigate('/');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setMessage(`Sign up failed: ${error.response.data.detail}`);
            } else {
                setMessage(`Sign up failed: ${error.message}`);
            }
        } finally {
            setIsRegistering(false);
        }
    };

    /**
     * Lida com o envio do código de confirmação via proxy.
     */
    const handleConfirmEmail = async () => {
        if (!captchaToken) {
            setMessage('Please complete the reCAPTCHA to continue.');
            return;
        }

        try {
            const response = await axios.post(
                `${config.apiHost}/proxy.php?endpoint=confirm_email`,
                {
                    email: email,
                    confirmation_code: confirmationCode,
                    recaptchaToken: captchaToken,
                }
            );
            const data = response.data;

            if (data.success) {
                setMessage('Email confirmed successfully! You can now log in.');
                setShowConfirmationField(false);
               
            } else {
                setMessage(`Confirmation failed: ${data.detail || 'Invalid code'}`);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setMessage(`Confirmation failed: ${error.response.data.detail}`);
            } else {
                setMessage(`Confirmation failed: ${error.message}`);
            }
        }
    };

    /**
     * Lida com o sucesso do login via Google.
     */
    const handleGoogleLoginSuccess = async (credentialResponse) => {
        try {
            const { credential } = credentialResponse;
            const response = await axios.post(`${config.apiHost}/proxy.php?endpoint=callback`, {
                token: credential,
            });
            const data = response.data;
            login({ email: data.email, id: data.id }, data.token, data.refresh_token);
            
            setMessage('Login successful');
            navigate('/');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setMessage(`Google sign-up/login failed: ${error.response.data.detail}`);
            } else {
                setMessage(`Google sign-up/login failed: ${error.message}`);
            }
        }
    };

    /**
     * Lida com a falha no login via Google.
     */
    const handleGoogleLoginFailure = () => {
        setMessage('Google sign-up/login failed. Please try again.');
    };

    /**
     * Atualiza o token do reCAPTCHA quando resolvido.
     */
    const handleRecaptchaChange = (value) => {
        setCaptchaToken(value);
        setIsRecaptchaLoading(false);
    };

    return (
        <div className="login-container">
            <div className="signUP-content">
                <div className="login-form">
                    {!showConfirmationField ? (
                        <>
                            <h2 className="login-title">Sign Up</h2>
                            <p className="login-subtitle">
                                Please sign up to access your account and stay connected with our community. Enter your details to continue.
                            </p>
                            <input
                                className="login-input"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <input
                                className="login-input"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="login-input-container">
                                <input
                                    className="login-input"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span
                                    className="password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? '👁️' : '👁‍🗨'}
                                </span>
                            </div>
                            <div className="login-input-container">
                                <input
                                    className="login-input"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Repeat Password"
                                    value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                />
                                <span
                                    className="password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? '👁️' : '👁‍🗨'}
                                </span>
                            </div>
                            {isRegistering ? (
                                <p>Registering...</p>
                            ) : (
                                <button className="login-button" onClick={handleSignUp}>
                                    Sign Up
                                </button>
                            )}
                            <p className="register-link">
                                Already have an account? <a href="/">Login</a>
                            </p>
                            <center style={{ marginTop: '15px' }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleLoginSuccess}
                                    onError={handleGoogleLoginFailure}
                                    className="google-signup-button"
                                />
                            </center>
                        </>
                    ) : (
                        <>
                            <h2 className="login-title">Confirm Your Email</h2>
                            <p className="login-subtitle">
                                Please enter your confirmation code sent to your email:
                            </p>
                            <input
                                className="login-input"
                                type="text"
                                placeholder="Confirmation Code"
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                            />
                            <ReCaptchaComponent onChange={handleRecaptchaChange} />
                            {captchaToken ? (
                                <button className="login-button" onClick={handleConfirmEmail}>
                                    Confirm Email
                                </button>
                            ) : isRecaptchaLoading ? (
                                <p style={{ color: '#999', fontWeight: 'bold' }}>
                                    Loading reCAPTCHA...
                                </p>
                            ) : (
                                <p style={{ color: '#999', fontWeight: 'bold' }}>
                                    Please complete the reCAPTCHA to enable confirmation.
                                </p>
                            )}
                        </>
                    )}
                    <p className="login-message">{message}</p>
                </div>
                <div className="login-image"></div>
            </div>
        </div>
    );
};

export default SignUpPage;
